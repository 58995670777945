<template>
	<v-sheet
		class="activity"
		style="height: calc(100vh - 88px)"
		v-if="getPermission('activity::view')"
	>
		<v-row>
			<v-col md="4" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:items="dListingStatus"
					label="Status"
					solo
					disabled
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.asset_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="8" class="py-1 my-auto text-right listing-right">
				<v-layout class="justify-end">
					<div class="mx-2" style="max-width: 300px">
						<text-input
							v-on:click:clear="
								s_search['search'] = null;
								searchOrders();
							"
							hide-details
							hide-top-margin
							clearable
							:disabled="pageLoading"
							:loading="pageLoading"
							placeholder="Search..."
							v-model="s_search['search']"
						></text-input>
					</div>
					<div class="mx-2" style="max-width: 300px">
						<date-range-picker
							v-on:click:clear="
								s_search['date-range'] = [];
								searchOrders();
							"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							hide-top-margin
							clearable
							v-model="s_search['date-range']"
						></date-range-picker>
					</div>
					<div class="mx-2" style="max-width: 300px">
						<select-input
							v-on:click:clear="
								s_search['module'] = [];
								searchOrders();
							"
							clearable
							hide-top-margin
							:items="module_items"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							custom-class="pt-0"
							placeholder="Module"
							multiple
							v-model="s_search['module']"
						></select-input>
					</div>
					<div class="mx-2" style="max-width: 300px">
						<select-input
							v-on:click:clear="
								s_search['user'] = [];
								searchOrders();
							"
							clearable
							hide-top-margin
							:items="user_lists"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							custom-class="pt-0"
							placeholder="Users"
							multiple
							v-model="s_search['user']"
						></select-input>
					</div>
					<div class="d-flex">
						<v-btn
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							:disabled="pageLoading"
							v-on:click="searchOrders()"
							><v-icon center> mdi-magnify </v-icon></v-btn
						>
					</div>
				</v-layout>
			</v-col>
		</v-row>
		<SearchCriteria
			is-activity
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Activity Description"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table :page-loading="pageLoading" type="activity" v-on:reload:content="filterRows"></Table>
	</v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
import SelectInput from "@/view/components/SelectInput";
import ListingMixin from "@/core/mixins/listing.mixin";
import DateRangePicker from "@/view/components/DateRangePicker";
import ObjectPath from "object-path";
import { isString } from "lodash";
import { SET_SEARCH_BAR } from "@/core/services/store/listing.module";

export default {
	name: "activity-log-listing",
	title: "Listing Activity Log",
	mixins: [ListingMixin],
	data() {
		return {
			pageTitle: "Activity Logs",
			pageBreadcrumbs: [{ text: "Activity Log", disabled: true }],
			endpoint: "activity-log",
			defaultFilter: {},
			user_lists: [],
			module_items: [
				{
					text: "Customer",
					value: "customer",
				},
				{
					text: "Eng-Contractor",
					value: "eng-contractor",
				},
				{
					text: "Asset",
					value: "asset",
				},
				{
					text: "Scheduled Service",
					value: "service",
				},
				{
					text: "Invoice",
					value: "invoice",
				},
				{
					text: "Payment",
					value: "payment",
				},
			],
			dListingStatus: [
				{
					id: 1,
					type: "activity",
					text: "All Activities",
					value: "all",
					description: "List of All Activities",
					color: "blue",
					textcolor: "white",
					dbvalue: 99,
					status_count: 0,
					order: 0,
				},
			],
			s_search: {
				"date-range": [],
				module: [],
				user: [],
				search: null,
			},
		};
	},
	methods: {
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.s_search,
					},
				})
				.then(() => {
					this.getListing();
				})
				.catch(() => {});
		},
	},
	components: {
		TextInput,
		SelectInput,
		DateRangePicker,
	},
	computed: {
		...mapGetters(["localDB"]),
	},
	mounted() {
		this.user_lists = this.localDB("users", []);

		this.$store.dispatch(SET_SEARCH_BAR, false);

		this.s_search["search"] = ObjectPath.get(this.$route, "query.search", null);

		const date_range = ObjectPath.get(this.$route, "query.date-range", []);
		if (isString(date_range)) {
			this.s_search["date-range"] = [date_range];
		} else {
			this.s_search["date-range"] = date_range;
		}

		const dmodule = ObjectPath.get(this.$route, "query.module", []);
		if (isString(dmodule)) {
			this.s_search["module"] = [dmodule];
		} else {
			this.s_search["module"] = dmodule;
		}

		const duser = ObjectPath.get(this.$route, "query.user", []);
		if (isString(duser)) {
			this.s_search["user"] = [duser];
		} else {
			this.s_search["user"] = duser;
		}
	},
};
</script>
